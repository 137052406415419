
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import SelectWalletSection from '@/components/sections/SelectWalletSection.vue'

export default defineComponent({
  name: 'SelectWallet',
  components: {
    Main,
    SelectWalletSection
  }
})
