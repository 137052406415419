
import { defineComponent } from 'vue'
import { Form } from 'vee-validate'
import * as yup from 'yup'

export default defineComponent({
  name: 'SelectWalletSection',
  data () {
    const schema = yup.object().shape({
      account: yup.number().required('Введите только цифры')
    })

    return {
      wallets: {},
      errorData: null,
      translateData: {},
      formData: {
        schema,
        account: '',
        amount: Number
      }
    }
  },
  components: {
    Form
  },
  mounted () {
    this.getAllWallets()
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('add_new', 'delete', 'back', 'withdraw')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToNewWallet () {
      this.$router.push('/dashboard/new-wallet')
    },
    getAllWallets () {
      this.$store.dispatch('getPayments/getWallets').then(
        (response) => {
          this.wallets = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    withdrawMoney () {
      const amountData = Object.keys(this.$route.query)[0]
      const { formData } = this
      const data = {
        account: formData.account,
        amount: amountData
      }
      this.$store.dispatch('getPayments/withdrawMoney', data).then(
        (response) => {
          this.$router.push('/dashboard/thank-output')
          console.log(response)
        },
        (error) => {
          this.errorData = error.message
        }
      )
    },
    deleteWallet (data) {
      this.$store.dispatch('getPayments/deleteWallet', data).then(
        () => {
          this.getAllWallets()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
